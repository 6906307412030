/* Base styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #333;
    color: white;
    width: 100%;
    box-sizing: border-box;
    height: auto !important;
    /* position: static !important; */
    
  }
  
  .headerContainer {
    max-width: 1200px; /* Limit the header width */
    margin: 0 auto;    /* Center the header */
    width: 100%;       /* Ensure it spans across the screen */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  a {
    color: var(--primary);
  }
  a:hover {
    color: var(--secondary);
  }
  .nav {
    display: flex;
    z-index: 100000;
  }
  
  .navList {
    display: flex;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navLink {
    text-decoration: none;
    color: white;
    transition: color 0.3s ease;
  }
  
  .navLink:hover {
    color: #00bcd4;
  }
  nav button {
    display: none;
  }
  .nav.active button {
    display: block;
  }
  /* Responsive styles */
  .menuToggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .nav {
      display: none;
      flex-direction: column;
      background-color: #444;
      position: absolute;
      top: 0%;
      right: -200px;
      width: 200px;
      height: 100vh;
      transition: right 3s;
    }
    .navList {
      flex-direction: column;
      gap: 1rem;
    }
  
    .menuToggle {
      display: block;
    }
  
    .nav.active {
      display: flex;
      right: 0;
      transition: right 3s;
      padding: 20px;
    }
  }
  