:root {
    --text-light: #ffffff90;
    --text: #d4d4d4;
    --background: #0f0f0f; /* Dark gray background */
    --background-overlay: #0f0f0f99;
    --background-light: #1a1a1a; /* Slightly lighter gray background */
    --background-lighter: #232323; /* Even lighter gray background */
    --background-img: #24242450; /* Grayish background with reduced opacity */
    --overlay: #0f0f0f80; /* Dark gray overlay with reduced opacity */
    --primary: #005f9e;
    --primary-overlay: #005f9e50;
    --secondary: #42bbac;
    --accent: #6cb3e1;
    --side-padding: 100px;
    --primary-light: #227fff;
    --icon-background: #ffffff30;
    --popup: #27D964; /* Bright green for popups */
    --closer-hover: #a6a6a650;
}
html {
    width: 100vh !important;
    overflow-x: hidden !important;
}
body {
    margin: 0 !important;
}
form {
    /* width: 350px;
    border-radius: 8px;
    background-color: var(--overlay); */
    /* border: 1px solid red; */
    /* position: relative; */
}

.card {
    height: fit-content;
    width: 70%;
    max-width: 300px;
    position: relative;
    transition: transform .6s linear;
    transform-style: preserve-3d;
    margin-right: auto;
    margin-left: auto;
    height: 450px;
    /* backface-visibility: hidden; */
}

.back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    padding: 8px;
}
.button {
    box-shadow: 0px 0px 15px 5px var(--primary-overlay);
}
.button a {
    color: var(--text);
    padding: 12px;
}
.button {
    width: 160px;
    height: 50px;
    background-color: var(--primary);
    border-radius: 8px;
    margin-top: 22px;
    cursor: pointer;
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    outline: 0;
    border: 0;
}

.button:hover, .button-two {
    border: 1px solid var(--primary);
    background-color: transparent;
    color: var(--primary);
}
.button:hover a, .button-two a {
    color: var(--primary);
}
.button-two:hover {
    background-color: var(--primary);
}
.button-two:hover a {
    color: var(--text);
}
.front,
.back {
    height: fit-content;
    width: 100%;
    backface-visibility: hidden !important;
    padding: 8px;
}

.card-flipped {
    transform: rotateY(180deg);
}

.back {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.back,
.front {
    border-radius: 8px;
    background-color: var(--overlay);
}
.home-front {
    display: flex;
}


canvas {
    position: absolute !important;
    z-index: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
}

header {
    z-index: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    top: 0;
    left: 0;
}
.back-button {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    font-size: 18px;
    transition: color .3s ease;
}

.back-button:hover a {
    color: var(--text);
}

body {
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(https://wallpapercave.com/wp/wp7930319.png);
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
}

.color {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0%;
    z-index: -1;
    background: linear-gradient(to top, var(--background), var(--primary-overlay));
}

.input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.text-inp {
    background-color: transparent;
    border: 0;
    /* border-bottom: 1px solid var(--text-light); */
    padding: 5px 5px;
    position: relative;
    color: white;
}

.text-inp-lbl {
    font-size: 12px;
    color: var(--text-light);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 5px;
    transition: .3s ease-in-out;
}

.line {
    position: absolute;
    content: "";
    bottom: 20%;
    left: 50%;
    width: calc(100% - 40px);
    transform: translateX(-50%);
    height: 1px;
    background-color: var(--text-light);
}

.text-inp:focus {
    outline: 0;
    border-color: var(--primary);
}



.text-inp:focus+.text-inp-lbl,
.text-inp:not(:placeholder-shown)+.text-inp-lbl {
    top: 15px;
    font-size: 14px;
    padding-left: 0;
    color: var(--text);
}

.text-inp:focus~.line {
    height: 3px;
    background-color: var(--primary);
    box-shadow: 0px 0px 5px 1px var(--primary-overlay);
}

.button {
    margin-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
}

.submit {
    display: flex;
    justify-content: center;
}

.check-wrapper {
    padding: 20px;
}

input[type=checkbox] {
    display: none;
}
a {
    text-decoration: none !important;
}
.checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: var(--background-lighter);
    border-radius: 4px;
    position: relative;
    transition: background-color .15s ease, box-shadow .15s ease;
}

.check-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}

input[type=checkbox]~.checkmark::before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: translate(-50%, -60%) rotate(45deg);
    opacity: 0;
    transition: opacity .1s ease;
    z-index: 2;
}

input[type=checkbox]:checked~.checkmark::before {
    opacity: 1;
}

input[type=checkbox]:checked~.checkmark {
    background-color: var(--primary);
    box-shadow: 0px 0px 10px 1px var(--primary);
}

.check-wrapper label {
    color: var(--text);
    position: relative;
}

.check-wrapper label a {
    color: var(--primary);
    font-weight: bold;
}

.switch-link {
    display: block;
    /* This makes it a block element, taking up the full width of its container */
    margin-top: 10px;
    /* Adjust the margin to control the spacing between the "Submit" button and the link */
    color: var(--primary-overlay);
    /* Text color */
    text-align: center;
    /* Center the text horizontally */
    text-decoration: none;
    /* Remove the underline by default for links */
    font-weight: bold;
    /* Make the text bold or adjust font weight as desired */
    font-size: 14px;
    /* Adjust the font size as desired */
    cursor: pointer;
}

.switch-link:hover {
    color: var(--primary);
    /* Change color on hover */
}

.checkmark,
.check-wrapper label {
    cursor: pointer;
}




@media (max-width: 500px) {
    form {
        width: 80%;
    }

    .check-wrapper label {
        font-size: 14px
    }

    ;
}

