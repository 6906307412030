.container {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    line-height: 1.6;
    background-color: var(--background-light);
    color: var(--text);
    border-radius: 8px;
    overflow-y: auto; /* Allows scrolling if content exceeds the view */
    height: 100%; /* Ensures it takes full available height */
}

.title {
    text-align: center;
    color: var(--primary);
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.subheading {
    font-size: 1.5rem;
    margin-top: 30px;
    color: var(--primary);
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

p {
    font-size: 1rem;
    margin-bottom: 20px;
}

strong {
    color: var(--primary);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .container {
        padding: 15px;
        max-width: 100%;
        margin: 0 10px;
    }

    .title {
        font-size: 2rem; /* Smaller font size on mobile */
    }

    .subheading {
        font-size: 1.2rem; /* Smaller subheading */
    }

    p, ul li {
        font-size: 0.9rem; /* Slightly smaller text for mobile screens */
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.6rem; /* Further reduce title size */
    }

    .subheading {
        font-size: 1rem; /* Adjust subheading for very small screens */
    }

    p, ul li {
        font-size: 0.85rem; /* Decrease font size more for smaller screens */
    }

    .container {
        padding: 10px;
    }
}
html {
    overflow: scroll;
    height: max-content;
}