.debug {
	position: fixed;
	top: 4px;
	left: 50%;
	transform: translateX(-50%);.debug {
		position: fixed;
		top: 4px;
		left: 50%;
		transform: translateX(-50%);
		padding: 4px;
		background: white;
		border: 1px solid rgba(0, 0, 0, 0.3);
		font-family: monospace;
		font-size: 1.6rem;
	}
	#root {
		width: 100%;
	}
	html, body {
		height: 100%;
	}
	
	body {
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(45deg, grey 0%, lightgray 100%);
	}
	
	.slots {
		position: relative;
		width: 3.5 * 79px;
		height: 3 * 79px;
		display: flex;
		justify-content: space-between;
		padding: 0.3 * 79px;
		background: linear-gradient(45deg, grey 0%, lightgray 100%);
		border-top: 1px solid rgba(255, 255, 255, 0.6);
		border-right: 1px solid rgba(255, 255, 255, 0.6);
		border-left: 1px solid rgba(0, 0, 0, 0.4);
		border-bottom: 1px solid rgba(0, 0, 0, 0.4);
		box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
		border-radius: 3px;
	}
	
	.slots::before,
	.slots::after {
		content: "";
		position: absolute;
		top: 50%;
		width: 10px;
		height: 2px;
		background-color: rgba(0, 0, 0, 0.5);
	}
	
	.slots::before {
		left: 0;
		transform: translate(-200%, -50%);
	}
	
	.slots::after {
		right: 0;
		transform: translate(200%, -50%);
	}
	
	@keyframes win1 {
		0% {
			background: linear-gradient(45deg, orange 0%, yellow 100%);
			box-shadow: 0 0 80px orange;
		}
		100% {
			background: linear-gradient(45deg, grey 0%, lightgrey 100%);
			box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
		}
	}
	
	@keyframes win2 {
		0% {
			background: linear-gradient(45deg, lightblue 0%, lightgreen 100%);
			box-shadow: 0 0 80px lightgreen;
		}
		100% {
			background: linear-gradient(45deg, grey 0%, lightgrey 100%);
			box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
		}
	}
	
	.slots.win1 {
		animation: win1 200ms steps(2, end) infinite;
	}
	
	.slots.win2 {
		animation: win2 200ms steps(2, end) infinite;
	}
	
	.reel {
		position: relative;
		width: 79px;
		height: 79px;
		border: 1px solid rgba(0, 0, 0, 0.3);
		border-radius: 3px;
		overflow: hidden;
		background-image: url(https://assets.codepen.io/439000/slotreel.webp);
		background-position: 0 0;
		background-repeat: repeat-y;
	}
	
	.reel::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(rgba(0, 0, 0, 0.4) 0%, transparent 30%, transparent 70%, rgba(0, 0, 0, 0.4) 100%);
		box-shadow: inset 0 0 6px 2px rgba(0, 0, 0, 0.3);
	}
	
	.slots p {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		transform: translate(-50%, calc(100% + 30px));
		text-align: center;
		font-size: 0.80rem;
		color: #444;
	}
	
	.slots p:last-child {
		transform: translate(-50%, calc(100% + 60px));
	}
	
	.slots p a {
		color: red;
		font-weight: 700;
	}
	
	padding: 4px;
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.3);
	font-family: monospace;
	font-size: 1.6rem;
}

html, body {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(45deg, grey 0%, lightgray 100%);
}

.slots {
	position: relative;
	width: calc(3.5 * 79px);
	height: 3 * 79px;
	display: flex;
	justify-content: space-between;
	padding: 0.3 * 79px;
	background: linear-gradient(45deg, grey 0%, lightgray 100%);
	border-top: 1px solid rgba(255, 255, 255, 0.6);
	border-right: 1px solid rgba(255, 255, 255, 0.6);
	border-left: 1px solid rgba(0, 0, 0, 0.4);
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
}

.slots::before,
.slots::after {
	content: "";
	position: absolute;
	top: 50%;
	width: 10px;
	height: 2px;
	background-color: rgba(0, 0, 0, 0.5);
}

.slots::before {
	left: 0;
	transform: translate(-200%, -50%);
}

.slots::after {
	right: 0;
	transform: translate(200%, -50%);
}

@keyframes win1 {
	0% {
		background: linear-gradient(45deg, orange 0%, yellow 100%);
		box-shadow: 0 0 80px orange;
	}
	100% {
		background: linear-gradient(45deg, grey 0%, lightgrey 100%);
		box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
	}
}

@keyframes win2 {
	0% {
		background: linear-gradient(45deg, lightblue 0%, lightgreen 100%);
		box-shadow: 0 0 80px lightgreen;
	}
	100% {
		background: linear-gradient(45deg, grey 0%, lightgrey 100%);
		box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
	}
}

.slots.win1 {
	animation: win1 200ms steps(2, end) infinite;
}

.slots.win2 {
	animation: win2 200ms steps(2, end) infinite;
}

.reel {
	position: relative;
	width: 79px;
	height: 79px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	overflow: hidden;
	background-image: url(https://assets.codepen.io/439000/slotreel.webp);
	background-position: 0 0;
	background-repeat: repeat-y;
}

.reel::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(rgba(0, 0, 0, 0.4) 0%, transparent 30%, transparent 70%, rgba(0, 0, 0, 0.4) 100%);
	box-shadow: inset 0 0 6px 2px rgba(0, 0, 0, 0.3);
}

.slots p {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 100%;
	transform: translate(-50%, calc(100% + 30px));
	text-align: center;
	font-size: 0.80rem;
	color: #444;
}

.slots p:last-child {
	transform: translate(-50%, calc(100% + 60px));
}

.slots p a {
	color: red;
	font-weight: 700;
}
