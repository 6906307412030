:root {
    --text-light: #ffffff90;
    --text: #d4d4d4;
    --background: #0f0f0f;
    --background-light: #1a1a1a;
    --snake: #42bbac;
    --apple: #ff4d4d;
    --grid-border: #444;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    color: var(--text);
    height: 100vh;
    padding: 20px;
}

.title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--text);
}

.coins {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: var(--text-light);
}

.grid {
    display: grid;
    gap: 1px;
    background-color: var(--grid-border);
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1;
}

.cell {
    background-color: var(--background-light);
    width: 100%;
    height: 100%;
}

.snake {
    background-color: var(--snake);
}

.apple {
    background-color: var(--apple);
    border-radius: 50%;
}

.controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.controls button {
    background-color: var(--snake);
    color: var(--text-light);
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.controls button:hover {
    background-color: var(--apple);
}

.controls div {
    display: flex;
    gap: 10px;
}

.gameOver {
    font-size: 1.5rem;
    color: var(--apple);
    margin-top: 20px;
}
