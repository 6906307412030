/* Dice.module.css */
:root {
    --text-light: #ffffff90;
    --text: #d4d4d4;
    --background: #0f0f0f;
    --background-overlay: #0f0f0f99;
    --background-light: #1a1a1a;
    --background-lighter: #232323;
    --background-img: #24242450;
    --overlay: #0f0f0f80;
    --primary: #005f9e;
    --primary-overlay: #005f9e50;
    --secondary: #42bbac;
    --accent: #6cb3e1;
    --side-padding: 100px;
    --primary-light: #227fff;
    --icon-background: #ffffff30;
    --popup: #27D964;
    --closer-hover: #a6a6a650;
}

.gameContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background);
    color: var(--text);
    min-height: 100vh;
    justify-content: center;
    font-family: Arial, sans-serif;
    padding: 20px;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--primary-light);
}

.coins {
    font-size: 1.5rem;
    margin: 10px 0;
}

.betSection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.input {
    margin-left: 10px;
    padding: 5px;
    font-size: 1rem;
    border: 2px solid var(--accent);
    border-radius: 5px;
    background-color: var(--background-light);
    color: var(--text-light);
}

.button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: var(--text);
    background-color: var(--primary);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: var(--primary-light);
}

.message {
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: center;
    color: var(--popup);
}
