:root {
    --text-light: #ffffff90;
    --text: #d4d4d4;
    --background: #0f0f0f;
    --background-overlay: #0f0f0f99;
    --background-light: #1a1a1a;
    --background-lighter: #232323;
    --background-img: #24242450;
    --overlay: #0f0f0f80;
    --primary: #005f9e;
    --primary-overlay: #005f9e50;
    --secondary: #42bbac;
    --accent: #6cb3e1;
    --side-padding: 100px;
    --primary-light: #227fff;
    --icon-background: #ffffff30;
    --popup: #27D964;
    --closer-hover: #a6a6a650;
}

.app {
    background-color: var(--background);
    color: var(--text);
    padding: 20px;
    text-align: center;
    min-height: 100vh;
}

.title {
    color: var(--primary);
    margin-bottom: 20px;
}

.coins {
    color: var(--accent);
}

.controls {
    margin-bottom: 20px;
}

.controls label {
    display: inline-block;
    margin: 10px;
    color: var(--text-light);
}

.button {
    background-color: var(--primary);
    color: var(--text);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 12px;
}

.button:hover {
    background-color: var(--primary-light);
}

.gameBoard {
    display: grid;
    gap: 10px;
    justify-content: center;
}

.tile {
    width: 60px;
    height: 60px;
    background-color: var(--background-light);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--background-lighter);
    cursor: pointer;
}

.tile.revealed {
    background-color: var(--secondary);
}

.tile.mine {
    /* background-color: var(--popup); */
    color: var(--background);
}

.status {
    color: var(--popup);
    font-weight: bold;
    margin-top: 20px;
}
