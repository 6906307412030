:root {
    --text-light: #ffffff90;
    --text: #d4d4d4;
    --background: #0f0f0f;
    --background-overlay: #0f0f0f99;
    --background-light: #1a1a1a;
    --background-lighter: #232323;
    --background-img: #24242450;
    --overlay: #0f0f0f80;
    --primary: #005f9e;
    --primary-overlay: #005f9e50;
    --secondary: #42bbac;
    --accent: #6cb3e1;
    --side-padding: 100px;
    --primary-light: #227fff;
    --icon-background: #ffffff30;
    --popup: #27D964;
    --closer-hover: #a6a6a650;
}

.wheelOfFortune {
    color: var(--text);
    background-color: var(--background);
    padding: 20px;
    text-align: center;
}

.input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid var(--primary);
    background-color: var(--background-light);
    color: var(--text);
}

.button {
    padding: 10px 20px;
    background-color: var(--primary);
    color: var(--text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: var(--primary-light);
}

.button.disabled {
    background-color: var(--primary-overlay);
    cursor: not-allowed;
}

.wheelContainer {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px auto;
}

.arrow {
    position: absolute;
    top: -10px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid var(--popup);
}

.wheel {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid var(--primary);
    position: relative;
    transform: rotate(0deg);
    transition: transform 3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    overflow: hidden;
}

.segment {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 0;
    left: 50%;
    transform-origin: 0% 100%;
    background-color: var(--background-lighter);
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
}

.segment:nth-child(even) {
    background-color: var(--background-light);
}

.segment span {
    position: absolute;
    top: 50%;
    left: 10%;
    transform-origin: 50% 50%;
    transform: rotate(-45deg);
    font-size: 14px;
    color: var(--text-light);
}
