.footer {
    width: 100vw; /* Full width of the viewport */
    background-color: var(--background);
    color: var(--text);
    padding: 20px;
    text-align: center;
    z-index: 1000; /* Ensures it stays on top */
    margin-top: 20px;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }
  
  .footer li {
    display: inline;
    margin: 0 15px;
  }
  
  .footer a {
    text-decoration: none;
    color: var(--text-light);
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .footer a:hover {
    color: var(--primary);
  }
  
  html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .main-content {
    min-height: 100%;
    padding-bottom: 80px; /* Ensure content isn't hidden behind the fixed footer */
  }
  